<template>
  <div class="aircraftDetail">
    <div class="aircraftDetail__top">
      <div class="aircraftDetail__top-inner" >
        <div class="aircraftDetail__caption">{{category}}</div>
        <div class="aircraftDetail__title">{{title}}</div>
      </div>
    </div>
    <div class="aircraftDetail__bottom">
      <div class="aircraftDetail__left">
        <AircraftDetailList :details="detailsLeft"/>
      </div>
      <div class="aircraftDetail__center">
        <AircraftDetailSlider :type="'aircraft'" :aircraftImages="aircraftImages"/>
      </div>
      <div class="aircraftDetail__right">
        <AircraftDetailList :details="detailsRight"/>
      </div>
    </div>
  </div>
</template>

<script>
  import AircraftDetailList from '@/components/AircraftDetail/AircraftDetailList'
  import AircraftDetailSlider from '@/components/AircraftDetail/AircraftDetailSlider'
  import '@/components/AircraftDetail/detail.styl'

  export default {
    props: ['category', 'title', 'detailsLeft', 'detailsRight', 'aircraftImages'],
    name: 'AircraftDetail',
    data: () => ({}),
    components: {
      AircraftDetailList,
      AircraftDetailSlider,
    },
  }
</script>
