<template>
  <div class="aircraftDetail__list">
    <div v-for="detail of details" class="aircraftDetail__list-item" :key="detail.title" style="display: flex;">
      <div class="aircraftDetail__list-item-icon" style="margin-right: 14px;">
        <SvgIcon :name="detail.icon" width="17" height="17" viewbox="0 0 17 17" fill="#c6c6c6"/>
      </div>
      <div class="aircraftDetail__list-item-text">
        <span class="aircraftDetail__list-item-title" style="color: #7c7c7c;">{{detail.title}} </span>
        <a v-if="detail.title === 'Insurance:'" class="aircraftDetail__list-item-link" href="#"></a>
        <span v-else class="aircraftDetail__list-item-value" style="color: #292931;">{{(detail.value === undefined || detail.value === null || detail.value === '') ? '' : detail.value}}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'

  export default {
    props: ['details'],
    name: 'AircraftDetailList',
    components: {
      SvgIcon,
    },
  }
</script>
